import React from 'react';

import dynamic from 'next/dynamic';

import PostContentV2 from '@components/pages/blog/SinglePost/PostContent/Components/v2';

import { PostContentProps } from '@customTypes/blog/types';

const PostContentV1 = dynamic(() => import('@components/pages/blog/SinglePost/PostContent/Components/v1'), {
    ssr: true,
});

interface WPVersionProps extends PostContentProps {
    wp_version: string;
}

export default function PostContent({ contentHtml, stripCss, wp_version }: WPVersionProps): JSX.Element {
    return (
        <>
            {wp_version !== '6.2' && wp_version !== '6.3.1' ? (
                <PostContentV1
                    contentHtml={contentHtml}
                    stripCss={stripCss}
                />
            ) : (
                <PostContentV2
                    contentHtml={contentHtml}
                    stripCss={stripCss}
                />
            )}
        </>
    );
}
