import React, { useId, useState } from 'react';

import useTranslation from '@hooks/useTranslation';

import styles from './TableOfContents.module.css';
import chevron from './chevron.svg';
import icon from './icon.svg';

interface TableOfContentsProps {
    headers: {
        id: string;
        content: string;
    }[];
}
function TableOfContents({ headers }: TableOfContentsProps) {
    const { t } = useTranslation('pages/blog/single/index');
    const id = useId();
    const [expanded, setExpanded] = useState(false);
    if (headers.length === 0) {
        return null;
    }

    const handleButtonClick = () => {
        setExpanded(!expanded);
    };

    function TOCItem({
        item,
    }: {
        item: { id: string; content: string; children?: Array<{ id: string; content: string }> };
    }) {
        let children: React.JSX.Element | null | undefined = null;
        if (item.children && item.children?.length) {
            children = (
                <ol className={styles.listNested}>
                    {item.children.map((i) => (
                        <li key={i.id}>
                            <a
                                href={`#${i.id}`}
                                dangerouslySetInnerHTML={{ __html: i.content }}
                            />
                            {children}
                        </li>
                    ))}
                </ol>
            );
        }

        return (
            <li key={item.id}>
                <a
                    href={`#${item.id}`}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                />
                {children}
            </li>
        );
    }
    return (
        <nav
            aria-labelledby={id}
            className={[styles.container, expanded ? styles.expanded : null].filter(Boolean).join(' ')}
        >
            <p
                id={id}
                className={styles.header}
            >
                <img
                    src={icon.src}
                    width={icon.width}
                    height={icon.height}
                    className={styles.icon}
                    alt=""
                />
                {t('tableOfContents')}
            </p>

            <ol className={styles.list}>
                {headers.map((header) => (
                    <TOCItem
                        key={header.id}
                        item={header}
                    />
                ))}
            </ol>

            <button
                type="button"
                className={styles.button}
                onClick={handleButtonClick}
                aria-hidden="true"
            >
                <img
                    src={chevron.src}
                    width={chevron.width}
                    height={chevron.height}
                    alt=""
                />
            </button>
        </nav>
    );
}
export default TableOfContents;
