import { useEffect, useId, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';

import getConfig from 'next/config';
import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';
import Seo from '@components/global/Seo';
import Layout from '@components/layouts/Layout';
import Categories from '@components/pages/blog/Categories';
import Hr from '@components/pages/blog/Hr';
import Navigation, { Breadcrumb } from '@components/pages/blog/Navigation';
import RelatedFeatures from '@components/pages/blog/RelatedFeatures';
import JsonLd from '@components/pages/blog/SinglePost/JsonLd';
import PostContent from '@components/pages/blog/SinglePost/PostContent';
import ShareBar from '@components/pages/blog/SinglePost/ShareBar';
import TableOfContents from '@components/pages/blog/TableOfContents';
import RelatedPosts from '@components/pages/blog/redesign2023/RelatedPosts';
import SinglePostAuthor from '@components/pages/blog/redesign2023/SinglePostAuthor';
import SinglePostBox, { SinglePostBoxTag } from '@components/pages/blog/redesign2023/SinglePostBox';
import SinglePostHeader from '@components/pages/blog/redesign2023/SinglePostHeader';
import SubscribeForm from '@components/pages/blog/redesign2023/SubscribeForm';

import { getPost, getRelatedPosts, getTopTags } from '@helpers/blog/helpers';
import getGlobalProps from '@helpers/getGlobalProps';
import getLocale from '@helpers/getLocale';
import getPageLocales from '@helpers/getPageLocales';
import { PropsWithSeoData } from '@helpers/seo';

import Style from '@public/pages/blog/single/Style';

import type {
    BlogSupportedLocales,
    PostProps,
    SingleBlogPostInterface,
    TOCHeader,
    TranslationSlug,
} from '@customTypes/blog/types';
import type { ESPostSource } from '@customTypes/elasticsearch';
import getRedirects from '@data/Redirects';
import { Client } from '@elastic/elasticsearch';
import type { Client as NewTypes } from '@elastic/elasticsearch/api/new';
import useTranslation from '@hooks/useTranslation';
import { laravelRouteBlog, laravelRouteBlogSearch } from '@static_components/laravelLinks';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import type { DOMNode, Element, HTMLReactParserOptions } from 'html-react-parser';
import type { GetStaticPaths, GetStaticProps } from 'next';

import styles from './index.module.css';

const { publicRuntimeConfig } = getConfig();

const { serverRuntimeConfig } = getConfig();

const BUILD_TIME_ELASTICSEARCH_HOST = serverRuntimeConfig.buildTimeElasticsearchHost;
const BUILD_TIME_ELASTICSEARCH_KNOWLEDGE_INDEX = 'knowledge';
const ES_INDEX_NAME =
    process.env.BUILD_PHASE === 'build'
        ? BUILD_TIME_ELASTICSEARCH_KNOWLEDGE_INDEX
        : process.env.ELASTICSEARCH_KNOWLEDGE_INDEX;

const ES_CONFIG = {
    node: process.env.BUILD_PHASE === 'build' ? BUILD_TIME_ELASTICSEARCH_HOST : process.env.ELASTICSEARCH_HOST,
    auth: {
        username: process.env.ELASTICSEARCH_USERNAME || '',
        password: process.env.ELASTICSEARCH_PASSWORD || '',
    },
};

function Container({ children }: React.PropsWithChildren): JSX.Element {
    return <div className={styles.container}>{children}</div>;
}

export default function SingleBlogPost({
    post,
    relatedPosts,
    alternateQuery,
    headers,
    features,
    seo,
}: PropsWithSeoData<SingleBlogPostInterface>) {
    const { locale } = useRouter();

    const { t } = useTranslation('pages/blog/index');
    const [tableOfContentsStuck, setTableOfContentsStuck] = useState(false);

    const tableOfContentsHelperRef = useRef(null);

    const categories: { name: string; href: string }[] = [];
    if (post.primaryTag) {
        categories.push({
            name: post.primaryTag.name,
            href: laravelRouteBlog(locale) + `/topic/${post.primaryTag.slug}`,
        });
    }
    if (post.postTags) {
        categories.push(
            ...post.postTags.map((tag) => ({
                name: tag,
                href: laravelRouteBlogSearch(locale) + `?query=${encodeURIComponent(tag)}`,
            })),
        );
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            setTableOfContentsStuck(!entries[0].isIntersecting);
        });
        if (tableOfContentsHelperRef.current) {
            observer.observe(tableOfContentsHelperRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [tableOfContentsHelperRef]);

    return (
        <>
            <Style />
            <Seo
                title={post.metaTitle ?? post.title}
                description={post.metaDescription ?? post.excerpt}
                alternateQuery={alternateQuery}
                languageAlternates={Object.keys(post.translations_slug).length == 1 ? [] : undefined}
                ogImage={post.og_image && post.og_image.length ? post.og_image : post.media ?? null}
                ogTitle={post.og_title}
                ogDescription={post.og_description}
                noindex={post.hiddenForNonAffiliates ? true : !seo.robots.index}
                nofollow={post.hiddenForNonAffiliates ? true : !seo.robots.follow}
                canonical={seo.canonical}
            />
            <JsonLd post={post} />
            <main className={styles.main}>
                <Container>
                    <div className={styles.grid}>
                        <div className={styles.navigation}>
                            {locale && !['fr'].includes(locale) && (
                                <Navigation
                                    breadcrumbs={
                                        <>
                                            {post.primaryTag?.slug && (
                                                <Breadcrumb
                                                    href={laravelRouteBlog(locale) + `/topic/${post.primaryTag.slug}`}
                                                >
                                                    {post.primaryTag.name}
                                                </Breadcrumb>
                                            )}
                                            <Breadcrumb
                                                href={{ pathname: `blog/${post.slug}` }}
                                                isCurrent={true}
                                            >
                                                {post.title}
                                            </Breadcrumb>
                                        </>
                                    }
                                />
                            )}
                        </div>
                        <div className={styles.header}>
                            <SinglePostHeader
                                title={post.title}
                                author={post.author?.name ? post.author?.name : ''}
                                date={post.dateModified}
                                authorImage={post.author?.avatar2x ?? undefined}
                                authorLink={
                                    post.author?.author_slug ? `/blog/authors/${post.author?.author_slug}` : undefined
                                }
                                readTime={
                                    post.reading_time_minutes
                                        ? t('readingTimeValue', { minutes: post.reading_time_minutes })
                                        : undefined
                                }
                            />
                            {categories.length > 0 && <Categories tags={categories} />}
                        </div>

                        <div className={styles.mainColumn}>
                            <div className={styles.mobileOnly}>
                                <TableOfContents headers={headers} />
                            </div>
                            <PostContent
                                contentHtml={post.content_html}
                                stripCss={post.stripCss}
                                wp_version={post.wp_version}
                            />

                            {post.author &&
                                post.author.name &&
                                post.author.description &&
                                post.author.avatar2x &&
                                locale &&
                                !['fr'].includes(locale) && (
                                    <>
                                        <Hr />
                                        <SinglePostAuthor
                                            author={post.author.name}
                                            description={post.author.description}
                                            authorImage={post.author.avatar2x}
                                            authorLink={
                                                post.author?.author_slug
                                                    ? `/blog/authors/${post.author?.author_slug}`
                                                    : undefined
                                            }
                                        />
                                    </>
                                )}
                            <ShareBar postUrl={laravelRouteBlog(locale) + `/${post.slug}`} />
                        </div>
                        {relatedPosts.length > 0 && locale && !['fr'].includes(locale) && (
                            <aside className={styles.relatedPosts}>
                                <RelatedPosts>
                                    {relatedPosts.map((relatedPost) => {
                                        return (
                                            <SinglePostBox
                                                postId={Number(relatedPost.id)}
                                                key={relatedPost.slug}
                                                customLink={
                                                    <CustomLink href={{ pathname: `blog/${relatedPost.slug}` }} />
                                                }
                                                title={relatedPost.title}
                                                date={relatedPost.dateModified}
                                                tags={
                                                    <>
                                                        {relatedPost.primaryTag && (
                                                            <SinglePostBoxTag
                                                                href={
                                                                    laravelRouteBlog(locale) +
                                                                    `/topic/${relatedPost.primaryTag.slug}`
                                                                }
                                                                title={relatedPost.primaryTag.name}
                                                            />
                                                        )}
                                                    </>
                                                }
                                                author={relatedPost.author?.name ?? undefined}
                                                readTime={
                                                    relatedPost.reading_time_minutes
                                                        ? t('readingTimeValue', {
                                                              minutes: relatedPost.reading_time_minutes,
                                                          })
                                                        : undefined
                                                }
                                                image={relatedPost.media ?? undefined}
                                                authorImage={relatedPost.author?.avatar2x ?? undefined}
                                            />
                                        );
                                    })}
                                </RelatedPosts>
                            </aside>
                        )}
                        <aside className={styles.aside}>
                            <div
                                className={styles.tocStickyWrapper}
                                style={
                                    { '--position': tableOfContentsStuck ? 'sticky' : 'static' } as React.CSSProperties
                                }
                            >
                                <div className={styles.desktopOnly}>
                                    <TableOfContents headers={headers} />
                                </div>
                            </div>
                            <div ref={tableOfContentsHelperRef}>
                                {locale && !['fr'].includes(locale) && <SubscribeForm />}
                                <RelatedFeatures features={features} />
                            </div>
                        </aside>
                    </div>
                </Container>
            </main>
        </>
    );
}

export const getStaticProps: GetStaticProps = async (context) => {
    const locale = getLocale(context);
    const locales = getPageLocales('/blog/:slug');
    if (!locale || !locales.includes(locale)) {
        return {
            notFound: true,
        };
    }

    const headers: Array<TOCHeader> = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const H2 = ({ children, ...otherProps }: React.PropsWithChildren<{ [x: string]: any }>) => {
        const id = useId();
        headers.push({
            id: otherProps.id ?? id,
            content: renderToString(<>{children}</>),
        });

        return (
            <h2
                id={id}
                {...otherProps}
                className={`level-2 ${otherProps.className}`}
            >
                {children}
            </h2>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const H3 = ({ children, ...otherProps }: React.PropsWithChildren<{ [x: string]: any }>) => {
        const id = useId();
        const lastH2 = headers[headers.length - 1];
        if (lastH2 !== undefined) {
            lastH2.children = lastH2.children ?? [];
            lastH2.children.push({
                id: otherProps.id ?? id,
                content: renderToString(<>{children}</>),
            });
        }

        return (
            <h3
                id={id}
                {...otherProps}
                className={`${otherProps.className}`}
            >
                {children}
            </h3>
        );
    };

    // eslint-disable-next-line no-console
    console.log('REVALIDATE', context.params);

    const { params } = context as { params: { slug: string } };

    const post = await getPost(params.slug, locale as BlogSupportedLocales);

    if (post === null) {
        const fullUrl = `${publicRuntimeConfig.domains_root[locale]}/blog/${params.slug}`;
        const redirects = await getRedirects();
        const redirectFound: { target: string; code: string } = redirects.find(
            (redirect: { source: string }) => redirect.source.toLowerCase() === fullUrl.toLowerCase(),
        );
        if (redirectFound) {
            // eslint-disable-next-line no-console
            console.log('REDIRECT TO: ', redirectFound);
            return {
                redirect: {
                    destination: redirectFound.target,
                    permanent: redirectFound.code === '301' ? true : false,
                },
                revalidate: 10 * 60,
            };
        }

        return {
            notFound: true,
            revalidate: 1 * 60,
        };
    }

    const alternateSlugs = {} as TranslationSlug;
    (Object.keys(post.translations_slug) as Array<keyof typeof post.translations_slug>).map(
        (item: 'en' | 'pl' | 'de' | 'ru' | 'it' | 'es' | 'pt' | 'vi') => {
            const replaceItem = item.replace('vi', 'vn') as BlogSupportedLocales;
            const postTranslationSlug = post.translations_slug[item];

            if (typeof postTranslationSlug !== 'undefined') {
                alternateSlugs[replaceItem] = {
                    slug: postTranslationSlug,
                };
            }
        },
    );

    const [relatedPosts, topTags] = await Promise.all([
        getRelatedPosts(locale as BlogSupportedLocales, post.slug, post._id),
        getTopTags(locale as BlogSupportedLocales as BlogSupportedLocales),
    ]);

    const options: HTMLReactParserOptions = {
        replace(domNode) {
            const typedDomNode = domNode as Element;

            if (typedDomNode.name === 'img') {
                const props = attributesToProps(typedDomNode.attribs);
                if (props.src) {
                    let imageUrl = props.src as string;

                    if (imageUrl.startsWith('//')) {
                        imageUrl = `https:${imageUrl}`;
                    }

                    let modifiedImg;
                    let parsedUrl: URL;
                    try {
                        parsedUrl = new URL(imageUrl);
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.error('Invalid URL', imageUrl);

                        return null;
                    }
                    const { pathname, href, origin } = parsedUrl;
                    const allowedImageTypes = ['jpg', 'jpeg', 'png'];
                    const extension = pathname.split('.').at(-1) as string;
                    const imageType = extension.toLowerCase();
                    const srcSet = `${href}?width=412 412w, ${href}?width=653 653w, ${href}?width=824 824w, ${href}?width=1306 1306w`;
                    const sizes = '(max-width: 967px) min(calc(100vw - 60px), 653px), 653px';

                    if (origin === 'https://us-wd.gr-cdn.com' && allowedImageTypes.includes(imageType)) {
                        modifiedImg = (
                            <img
                                {...props}
                                src={href}
                                srcSet={srcSet}
                                sizes={sizes}
                                alt={typeof props.alt === 'string' ? props.alt : ''}
                            />
                        );
                    }
                    return modifiedImg;
                }
            }

            if (typedDomNode.attribs && (typedDomNode.name === 'h2' || typedDomNode.attribs['data-table-of-content'])) {
                const props = attributesToProps(typedDomNode.attribs);
                let modifiedHeader = null;

                if (typedDomNode.name === 'h2') {
                    modifiedHeader = (
                        <H2 {...props}>
                            {typedDomNode.children && domToReact(typedDomNode.children as DOMNode[], options)}
                        </H2>
                    );
                }

                if (typedDomNode.name === 'h3' && typedDomNode.attribs['data-table-of-content']) {
                    modifiedHeader = (
                        <H3 {...props}>
                            {typedDomNode.children && domToReact(typedDomNode.children as DOMNode[], options)}
                        </H3>
                    );
                }

                return modifiedHeader;
            }
            if (
                typedDomNode.name === 'details' &&
                typedDomNode.attribs.class.includes('wp-block-getresponse-table-of-contents')
            ) {
                return <></>;
            }
        },
    };

    const modifiedHtml = renderToString(<>{parse(post.content_html, options)}</>);

    const globalProps = await getGlobalProps(context, '/blog/[slug]');

    return {
        props: {
            alternateQuery: alternateSlugs,
            ...globalProps,
            post: { ...post, content_html: modifiedHtml },
            relatedPosts,
            topTags,
            headers,
            features: post.related_features,
        },
        revalidate: 3 * 60,
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    // @ts-expect-error @elastic/elasticsearch
    const esClient: NewTypes = new Client(ES_CONFIG);

    const response = await esClient.search<ESPostSource>({
        index: ES_INDEX_NAME ?? 'knowledge',
        body: {
            query: {
                bool: {
                    filter: [
                        { term: { source: 'blog' } },
                        {
                            term: { post_type: 'post' },
                        },
                    ],
                },
            },
        },
        sort: ['post_date:desc'],
        // size: 10000,
        size: 200,
        _source_includes: ['slug', 'post_type', 'lang', 'post_id'],
    });

    const docList: Array<PostProps> = response.body.hits.hits.map((hit) => ({
        slug: hit._source?.slug ?? undefined,
        id: hit._id as string,
        locale: hit._source?.lang.replace('vi', 'vn'),
    }));

    const paths = docList
        .filter((doc) => doc.slug)
        .map((doc) => ({
            params: {
                slug: doc.slug,
                locale: doc.locale,
            },
        }));

    return {
        paths,
        fallback: 'blocking',
    };
};

SingleBlogPost.getLayout = function getLayout(page: JSX.Element) {
    return <Layout headerProps={{ additionalHeaderAttribute: 'has-new-layout' }}>{page}</Layout>;
};
