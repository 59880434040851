import React from 'react';

import styles from './Categories.module.css';

interface CategoriesProps {
    tags: Array<{ name: string; href: string; custom_name?: string | null }>;
}

function Categories({ tags }: CategoriesProps): JSX.Element {
    return (
        <div className={styles.categories}>
            <ul className={styles.list}>
                {tags.map((tag, i) => {
                    return (
                        <li key={i}>
                            <a
                                href={tag.href}
                                className={styles.tag}
                            >
                                {tag.custom_name ? tag.custom_name : tag.name}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
export default Categories;
